/*eslint-disable */
var $ = window.jQuery = require('jquery');
import videoSocketRef from "@/services/videoNotificationSocket/";
import commonSocket from "@/services/socket/";
import {
  mapGetters,
} from 'vuex';
export default {
  name: 'jisti-video-confreness',
  components: {},
  props: [],
  data() {
    return {
      selfUserProfileDetails: {},
      apiObj:null,
      domain:process.env.VUE_APP_VIDEO_DOMAIN,
      callerRoomId:'',
      selfUserFullName:'',
      selfUserEmail:'',
      toolbarState:false,
      isAudioMuted:false,
      isVideoMuted:false,
      fullScreenState:true,
      selfUserId:'',
      selfUserInfo:null
    }
  },
  computed: {
  },
  mounted() {
    this.selfUserInfo = JSON.parse(localStorage.getItem('LoginedUserInfo'))
    if (!location.hash) {
      location.hash = Math.floor(Math.random() * 0xFFFFFF).toString(16);
    }
    this.getUserDetailsInfo
    this.setIntialUserIdFun
    this.StartMeetingMethod(this.callerRoomId, this.selfUserFullName, this.selfUserInfo.email)
    // detect enter or exit fullscreen mode
    var _self = this
    document.addEventListener('webkitfullscreenchange', isFullScreenChangeMethod);
    document.addEventListener('mozfullscreenchange', isFullScreenChangeMethod);
    document.addEventListener('fullscreenchange', isFullScreenChangeMethod);
    document.addEventListener('MSFullscreenChange', isFullScreenChangeMethod);
    function isFullScreenChangeMethod() {
      if (document.fullscreenEnabled || document.webkitIsFullScreen || document.mozFullScreen || document.msFullscreenElement) {
        if (document.webkitIsFullScreen == false) {
          _self.fullScreenState = true
        } else if (document.mozFullScreen == false) {
          _self.fullScreenState = true
        } else if (document.msFullscreenElement == false) {
          _self.fullScreenState = true
        }
      }
    }
  },
  created(){
    this.selfUserInfo = JSON.parse(localStorage.getItem('LoginedUserInfo'))
    this.selfUserFullName = this.selfUserInfo.first_name + this.selfUserInfo.last_name
    this.callerRoomId = location.hash.substring(1);
    this.selfUserId = localStorage.getItem('LoginedUserID')
  },
  methods: {
    StartMeetingMethod(roomName, dispNme, userEmail) {
      var __self = this
      const options = {
        roomName: roomName,
        width: '100%',
        height: '100%',
        parentNode: document.querySelector('#jitsi-meet-conf-container'),
        userInfo: {
          email:userEmail,
          displayName: dispNme
        },
        configOverwrite: {
          doNotStoreRoom: false,
          startWithVideoMuted: false,
          startWithAudioMuted: false,
          enableWelcomePage: false,
          prejoinPageEnabled: false,
          disableRemoteMute: false,
          remoteVideoMenu: {
            disableKick: true
          },
          enableNoAudioDetection: false,
          enableNoisyMicDetection: false,
          enableWelcomePage: false
        },
        interfaceConfigOverwrite: {
          filmStripOnly: true,
          SHOW_JITSI_WATERMARK: false,
          SHOW_WATERMARK_FOR_GUESTS: false,
          DEFAULT_LOGO_URL:'',
          DEFAULT_REMOTE_DISPLAY_NAME: '',
          TOOLBAR_BUTTONS: [],
          TOOLBAR_ALWAYS_VISIBLE: true,
          DEFAULT_BACKGROUND: '#0000000',
          HIDE_INVITE_MORE_HEADER: false,
        },
        onload(){
          __self.toolbarState = true
          $('#toolbox').show();
        },
      }
      this.apiObj = new JitsiMeetExternalAPI(this.domain, options);
      this.apiObj.on('readyToClose', () => {
        window.location.href = '/conference/user/left/room/#' + location.hash.substring(1);
      })
      this.apiObj.on('videoMuteStatusChanged', (status) => {
        if(status.muted === true){
          this.isVideoMuted = true
        }else{
          this.isVideoMuted = false
        }
      });
    },
    endCall(){
      this.apiObj.executeCommand('hangup');
    },
    screenShareMethod(){
      this.apiObj.executeCommand('toggleShareScreen');
    },
    toggleChatMethod(){
      this.apiObj.executeCommand('toggleChat');
    },
    toggleTileViewMethod(){
      this.apiObj.executeCommand('toggleTileView');
    },
    videoPushedMethod(){
      this.apiObj.executeCommand('toggleVideo');
      this.apiObj.isVideoMuted().then(muted => {
        if(muted === true){
          this.isVideoMuted = true
        }else{
          this.isVideoMuted = false
        }
      });
    },
    audioMutedMethod(){
      this.apiObj.executeCommand('toggleAudio');
      this.apiObj.isAudioMuted().then(muted => {
        if(muted === true){
          this.isAudioMuted = true
        }else{
          this.isAudioMuted = false
        }
      });
    },
    fullScreenMethod(){ 
      var elem = document.documentElement;
      if (elem.requestFullscreen) {
        elem.requestFullscreen();
      } else if (elem.mozRequestFullScreen) { /* Firefox */
        elem.mozRequestFullScreen();
      } else if (elem.webkitRequestFullscreen) { /* Chrome, Safari and Opera */
        elem.webkitRequestFullscreen();
      } else if (elem.msRequestFullscreen) { /* IE/Edge */
        elem.msRequestFullscreen();
      }
      this.fullScreenState = false
    },
    exitFullScreenMethod(){ 
      this.fullScreenState = true
      var elem = document.documentElement;
      if (document.exitFullscreen) {
        document.exitFullscreen();
      } else if (document.mozCancelFullScreen) { /* Firefox */
        document.mozCancelFullScreen();
      } else if (document.webkitExitFullscreen) { /* Chrome, Safari and Opera */
        document.webkitExitFullscreen();
      } else if (document.msExitFullscreen) { /* IE/Edge */
        document.msExitFullscreen();
      }
    },
  },
  beforeCreate: function () {
    if(commonSocket.isOpen()) {
      commonSocket.close();
    }
    if(videoSocketRef.isOpen()) {
      videoSocketRef.close();
    }
    $('body').addClass('video_conference_page')
    $('body').addClass('zisti_video_main_wrp')
  },
  // beforeMount() {
  //   window.addEventListener("beforeunload", this.leftUserInfoMethod)
  // },
  // beforeDestroy() {
  //   window.removeEventListener("beforeunload", this.leftUserInfoMethod);
  // },
  beforeRouteLeave: function (to, from, next) {
    $('body').removeClass('video_conference_page')
    $('body').removeClass('zisti_video_main_wrp')
    next();
  },
}