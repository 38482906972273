/*eslint-disable */

import Vue from "vue";

const url = 'wss://'+ process.env.VUE_APP_WEBSOCKET_BASE_URL + '/ws/video-chat/';

let socket;
var checkSocketStatus = false;
var CheckCommonRoomId;
const emitter = new Vue({
  methods: {
    isOpen() {
      let status = '';
      if(socket && socket.readyState) {
        status = socket.readyState;
      }
      return status;
    },
    emit(message) {
      socket.send(JSON.stringify(message));
    },
    send(message) {
      if (1 === socket.readyState) socket.send(message);
    },
    close() {
      checkSocketStatus = true
      if (socket.readyState && 1 === socket.readyState) {
        emitter.$emit("close", JSON.stringify({message: 'Closing Chat', type: 'connectionClosed'}));
        socket.close();
        socket = null; // prevent memory leak
      }
    },
    connect(roomId) {
      var __self = this
      CheckCommonRoomId = roomId
      socket = new WebSocket(url + roomId + "/");
      socket.onmessage = function(msg) {
        let message = JSON.parse(JSON.parse(msg.data).message);
        if(message.type === 'patient_remark') {
          emitter.$emit("patientRemark", message);
        }else if(message.type === 'patient_prescription') {
          emitter.$emit("patientPrescription", message);
        }else if(message.type === 'userLogin') {
          emitter.$emit("userLogin", message);
        }else if(message.type === 'userLogout') {
          if(message.videoCallingtypedata){
            emitter.$emit("autoMissedCallEvent", message.videoCallingtypedata);
          }
          emitter.$emit("userLogout", message);
        }
      };
      socket.onopen = function(event) {
        emitter.$emit("open", event);
        emitter.$emit("connection", JSON.stringify({message: 'connection created successfully', type: 'connection'}));
      };
      socket.onclose = function (event) {
        console.log(`WebSocket Error: `, event);
        if(checkSocketStatus == false){
          console.error('The Common socket closed has been automatically');
          setTimeout(()=>{
            __self.connect(CheckCommonRoomId)
            console.log('The Common socket reconnection has been automatically');
          },1000)
        }
      };
      socket.onerror = function(err) {
        console.log(`WebSocket Error:`, err);
        emitter.$emit("error", err);
      };
    }
  }
});

export default emitter;